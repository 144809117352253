import { TOptions } from 'i18next'
import IntlMessageFormat, { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { i18n, useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { TFuncReturn } from 'react-i18next'
import LocalizeNamespaces from 'shared/constants/localize-namespaces.mjs'

export type LocalizeKeys = keyof typeof LocalizeNamespaces
export type LocalizeValues = typeof LocalizeNamespaces[LocalizeKeys]

export type TLocoType<T = string, O = TOptions> = (
  translationWithNS?: T,
  options?: O,
) => TFuncReturn<any, any, any>

export type TICUType<T = string, O = TOptions> = (
  translationWithNS: T,
  values: Record<string, PrimitiveType | FormatXMLElementFn<string>>,
  options?: O,
) => string

export const useLocoTranslation = (namespaces: LocalizeValues[] = []) => {
  const { t: commonT, ...commonProps } = useTranslation()
  const { t: baseT, ...baseProps } = useTranslation([
    LocalizeNamespaces.header,
    LocalizeNamespaces.global,
    LocalizeNamespaces.notifications,
    LocalizeNamespaces.locale,
    ...namespaces,
  ])

  const t: TLocoType = useCallback(
    (translationWithNS, options) => {
      if (translationWithNS === undefined) {
        // To use with TransLoco component
        return ''
      }
      const basePart = translationWithNS.split('.')[0]
      switch (basePart) {
        case 'global':
        case 'core':
          return baseT(translationWithNS, { ns: LocalizeNamespaces.global, ...options })
        case 'header':
          return baseT(translationWithNS, { ns: LocalizeNamespaces.header, ...options })
        case 'confirmation_modal': {
          return baseT(translationWithNS, { ns: LocalizeNamespaces.confirmation, ...options })
        }
        case 'currency': {
          return baseT(translationWithNS, { ns: LocalizeNamespaces.currency, ...options })
        }
        case 'locale': {
          return baseT(translationWithNS, { ns: LocalizeNamespaces.locale, ...options })
        }
        case 'dashboard': {
          const namespaceFromKey = translationWithNS.split('.')[1]
          for (let namespace of [namespaceFromKey, ...Object.keys(LocalizeNamespaces)]) {
            const translation = baseT(translationWithNS, {
              ns: LocalizeNamespaces[namespace as LocalizeKeys],
              ...options,
            }) as LocalizeKeys
            if (translation && translation !== translationWithNS) return translation
          }
          return translationWithNS
        }
        default: {
          console.log(
            `Can't find namespace. Make sure you pass all used namespaces in the getBaseServerSideProps. Some troubles with "${translationWithNS}"`,
          )
          return ''
        }
      }
    },
    [baseT],
  )

  const tICU: TICUType = useCallback(
    (translationWithNS, value, options) => {
      const messageFormat = new IntlMessageFormat(t(translationWithNS, options))
      const formattedMessage = messageFormat.format(value)
      return Array.isArray(formattedMessage) ? formattedMessage.join('') : formattedMessage
    },
    [t],
  )

  const commonTICU: TICUType<string | string[]> = useCallback(
    (translationWithNS, value, options) => {
      const messageFormat = new IntlMessageFormat(commonT(translationWithNS, options))
      const formattedMessage = messageFormat.format(value)
      return Array.isArray(formattedMessage) ? formattedMessage.join('') : formattedMessage
    },
    [commonT],
  )

  if (i18n && i18n.options.ns?.includes('common')) {
    return { t: commonT, tICU: commonTICU, ...commonProps }
  }

  return { t, tICU, ...baseProps }
}
