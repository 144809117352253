import { SwrPaginationWithSortInterface } from 'shared/types/pagination-interface'

export interface GetPaginationQueryStringProps extends SwrPaginationWithSortInterface {}

export const getPaginationQueryString = ({ limit, pagination }: GetPaginationQueryStringProps) => {
  const queries = []

  queries.push(`pagination[order]=${pagination.order}`)

  if (pagination.startFromId !== undefined) {
    queries.push(`pagination[startFrom]=${pagination.startFromId}`)
  }

  if (limit !== undefined) {
    queries.push(`pagination[limit]=${limit}`)
  }

  if (pagination.sortDirection) {
    queries.push(`pagination[sortDirection]=${pagination.sortDirection}`)
  }

  if (pagination.sortColumn) {
    queries.push(`pagination[sortColumn]=${pagination.sortColumn}`)
  }

  return queries.join('&')
}
