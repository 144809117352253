import React from 'react'

function CommunityIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      fill={'#CBD5E1'}
      {...props}
    >
      <g>
        <path d="m256 0c-63.411 0-115 51.589-115 115s51.589 115 115 115 115-51.589 115-115-51.589-115-115-115zm40 130h-25v25c0 8.284-6.716 15-15 15s-15-6.716-15-15v-25h-25c-8.284 0-15-6.716-15-15s6.716-15 15-15h25v-25c0-8.284 6.716-15 15-15s15 6.716 15 15v25h25c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
        <g>
          <g>
            <path d="m317.869 420.196c-15.513 16.482-37.502 26.804-61.869 26.804s-46.356-10.322-61.869-26.804c-25.848 15.831-43.131 44.336-43.131 76.804 0 8.284 6.716 15 15 15h180c8.284 0 15-6.716 15-15 0-32.468-17.283-60.973-43.131-76.804z" />
            <path d="m256 417c-30.376 0-55-24.624-55-55v-30c0-30.376 24.624-55 55-55 30.376 0 55 24.624 55 55v30c0 30.376-24.624 55-55 55z" />
          </g>
          <path d="m407 382c-30.376 0-55-24.624-55-55v-30c0-30.376 24.624-55 55-55 30.376 0 55 24.624 55 55v30c0 30.376-24.624 55-55 55z" />
          <g>
            <g>
              <path d="m468.869 385.196c-15.513 16.482-37.502 26.804-61.869 26.804s-46.356-10.322-61.869-26.804c-4.341 2.659-8.433 5.682-12.249 9.015l.656.402c17.194 10.532 31.583 25.338 41.611 42.818 7.033 12.26 11.81 25.682 14.161 39.569h107.69c8.284 0 15-6.716 15-15 0-32.468-17.283-60.973-43.131-76.804z" />
              <path d="m178.462 394.612.656-.402c-3.816-3.332-7.909-6.356-12.249-9.015-15.513 16.483-37.502 26.805-61.869 26.805s-46.356-10.322-61.869-26.804c-25.848 15.831-43.131 44.336-43.131 76.804 0 8.284 6.716 15 15 15h107.69c2.351-13.887 7.127-27.309 14.161-39.569 10.028-17.481 24.417-32.286 41.611-42.819z" />
            </g>
            <path d="m105 382c-30.376 0-55-24.624-55-55v-30c0-30.376 24.624-55 55-55 30.376 0 55 24.624 55 55v30c0 30.376-24.624 55-55 55z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CommunityIcon
