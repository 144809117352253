import { ParsedUrlQuery } from 'querystring'

export const getQuery = (query: ParsedUrlQuery, keys: Array<keyof ParsedUrlQuery>) => {
  const relatedQuery = {} as ParsedUrlQuery
  const unrelatedQuery = {} as ParsedUrlQuery
  Object.entries(query).forEach(([key, value]) => {
    if (keys.includes(key)) {
      relatedQuery[key] = value
    } else {
      unrelatedQuery[key] = value
    }
  })

  return { relatedQuery, unrelatedQuery }
}
