import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { defaultPaginationOptions } from 'shared/route-query/constants/pagination-constants'
import { QueryKeysEnum } from 'shared/route-query/enum/query-keys-enum'
import { useQuery } from 'shared/route-query/hooks/use-query'
import { getLimitQuery } from 'shared/route-query/utils/get-limit-query'
import { getPaginationQuery } from 'shared/route-query/utils/get-pagination-query'
import { setLimitFromQuery } from 'shared/route-query/utils/set-limit-from-query'
import { setPaginationFromQuery } from 'shared/route-query/utils/set-pagination-from-query'
import {
  LimitType,
  PaginationInterface,
  PaginationOptions,
} from 'shared/types/pagination-interface'

export const usePagination = (
  isStaticUrl?: boolean,
  options: PaginationOptions = defaultPaginationOptions,
) => {
  const { query } = useRouter()
  const [limit, setLimit] = useState<LimitType>(setLimitFromQuery(query))

  const [pagination, setPagination] = useState<PaginationInterface>(
    setPaginationFromQuery(query, options),
  )

  const setFirstPage = useCallback(() => {
    setPagination({ startFromId: undefined, order: options.order })
  }, [options])

  const clearPagination = useCallback(() => {
    setFirstPage()
    setLimit(10)
  }, [setFirstPage])

  const memoizedQuery = useMemo(
    () => ({ ...getPaginationQuery(pagination), ...getLimitQuery(limit) }),
    [pagination, limit],
  )

  useQuery({
    data: memoizedQuery,
    skip: isStaticUrl,
    onChange: query => {
      const limit = setLimitFromQuery(query)
      setLimit(limit)

      const pagination = setPaginationFromQuery(query, options)
      setPagination(pagination)
    },
    keys: [QueryKeysEnum.Limit, QueryKeysEnum.StartFromId, QueryKeysEnum.Order],
  })

  return { limit, setLimit, pagination, setPagination, clearPagination, setFirstPage }
}
