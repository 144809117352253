import { ParsedUrlQuery } from 'querystring'
import { LimitType } from 'shared/types/pagination-interface'

export const getLimitQuery = (limit: LimitType) => {
  const newQuery: ParsedUrlQuery = {}
  if (limit !== 10) {
    newQuery['limit'] = limit.toString()
  }
  return newQuery
}
