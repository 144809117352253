// we need "replacer" to sort order of fields before comparing
const replacer = (_: any, value: any) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted, key) => {
          sorted[key] = value[key]
          return sorted
        }, {} as any)
    : value

export const deepCompare = <A, B>(a: A, b: B) => {
  return JSON.stringify(a, replacer) === JSON.stringify(b, replacer)
}
