import Router, { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useEffect } from 'react'
import { getQuery } from 'shared/route-query/utils/get-query'
import { deepCompare } from 'shared/utils/deepCompare'

interface QueryInterface<T> {
  data?: T
  onChange: (data: T) => void
  skip?: boolean
  keys: Array<keyof ParsedUrlQuery>
}

export const useQuery = <T extends ParsedUrlQuery>({
  data,
  onChange,
  skip,
  keys,
}: QueryInterface<T>) => {
  const { query } = useRouter()

  useEffect(() => {
    if (skip) return

    const { relatedQuery } = getQuery(query, keys)

    if (deepCompare(data, relatedQuery)) return

    onChange(relatedQuery as T)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, skip])

  useEffect(() => {
    if (skip || !data) return

    const { unrelatedQuery, relatedQuery } = getQuery(Router.query, keys)

    if (deepCompare(data, relatedQuery)) return

    Router.replace(
      {
        query: { ...unrelatedQuery, ...data },
      },
      undefined,
      { shallow: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, skip])
}
