import { Configuration } from 'rollbar'
import { PartialMatchIgnoredMessagesType } from './types'
import { checkIgnore } from './utils'

const ignoredMessages: Configuration['ignoredMessages'] = [
  'Write permission denied.',
  'ResizeObserver loop limit exceeded',
  'Script error.',
  //https://github.com/vercel/next.js/discussions/51551
  'ResizeObserver loop completed with undelivered notifications',
  "Cannot read properties of null (reading 'cropperPreview')",
  'Cancel rendering route',
  // https://app.rollbar.com/a/systemeio/fix/item/dashboard-frontend/4378
  "null is not an object (evaluating 't[e]')",
]

export const partialMatchIgnoredMessages: PartialMatchIgnoredMessagesType = [
  //https://app.rollbar.com/a/systemeio/fix/item/dashboard-frontend/3399
  [
    'The provided `href` (',
    ') value is missing query values (',
    ') to be interpolated properly. Read more: https://nextjs.org/docs/messages/href-interpolation-failed',
  ],
  // Dexie internal errors
  [
    'UnknownError Error creating or migrating Records table in database',
    'UnknownError: Error creating or migrating Records table in database',
  ],
  ['UnknownError Internal error.', 'UnknownError: Internal error.'],
  [
    'InvalidStateError A mutation operation was attempted on a database that did not allow mutations.',
    'InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.',
  ],
  [
    'UnknownError Error creating Records table (',
    'UnknownError Error creating Records table (',
    'UnknownError: Error creating Records table (',
    ') - database or disk is full',
  ],
  ['DatabaseClosedError'],
  ['QuotaExceededError'],
  // ACE Editor
  ["Failed to execute 'importScripts' on 'WorkerGlobalScope'"],
  // CROPPER
  ['cropperPreview'],
  ["null is not an object (evaluating 't[e]')"],
  ["Cannot read properties of null (reading 'offsetWidth')"],
  ["Cannot read properties of undefined (reading 'width')"],
  // React-international-phone
  ['e is not iterable'],
]

export const fullMatchIgnoredClasses: string[] = ['DatabaseClosedError', 'CKEditorError']

export const rollbarConfig: Configuration = {
  enabled: process.env.NEXT_PUBLIC_APP_ENV !== 'development',
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
  hostSafeList: [
    `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${process.env.NEXT_PUBLIC_SIO_HOST_NAME}`,
    `${process.env.NEXT_PUBLIC_STATIC_ASSETS_HOST}`,
  ],
  ignoredMessages,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
}
