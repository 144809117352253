import { SortDirectionEnum } from 'shared/components/table/enums'

export type LimitType = 10 | 25 | 50

export enum OrderEnum {
  next = 'next',
  prev = 'prev',
}

export interface PaginationOptions {
  order: OrderEnum
}

export interface DataWithPaginationInterface<T> {
  hasMore: boolean
  items: T[]
}

export interface PaginationInterface {
  startFromId?: number | string
  order: OrderEnum
}

export interface CountInterface {
  count: number
}

export interface SwrPaginationInterface {
  limit: LimitType
  pagination: PaginationInterface
}

export interface PaginationWithSortInterface extends PaginationInterface {
  sortDirection?: SortDirectionEnum | null
  sortColumn?: string
}

export interface SwrPaginationWithSortInterface {
  limit: LimitType
  pagination: PaginationWithSortInterface
}
