import { ParsedUrlQuery } from 'querystring'
import { PaginationInterface } from 'shared/types/pagination-interface'

export const getPaginationQuery = (pagination: PaginationInterface) => {
  const newQuery: ParsedUrlQuery = {}
  if (pagination.startFromId) {
    newQuery['startFromId'] = pagination.startFromId.toString()
  }
  if (pagination.order === 'prev') {
    newQuery['order'] = pagination.order
  }
  return newQuery
}
