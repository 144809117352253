import { ParsedUrlQuery } from 'querystring'
import { defaultPaginationOptions } from 'shared/route-query/constants/pagination-constants'
import { PaginationQueryType } from 'shared/route-query/types/pagination-query-interface'
import { PaginationInterface, PaginationOptions } from 'shared/types/pagination-interface'

export const setPaginationFromQuery = (
  query: ParsedUrlQuery,
  options: PaginationOptions = defaultPaginationOptions,
): PaginationInterface => {
  const paginationQuery = query as PaginationQueryType
  return {
    startFromId: paginationQuery.startFromId,
    order: paginationQuery.order ? paginationQuery.order : options.order,
  }
}
